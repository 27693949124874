import { StringHelper } from "./string-helper.js";
import { Messages } from "./messages.js";
import { NumberHelper } from "./number-helper.js";
import Handlebars from "handlebars";
import { DateHelper } from "./date-helper.js";

export class Templates {

    public static registerHelpers(): void {
        // kebab-case
        Handlebars.registerHelper("kebabcase", (s: string) => {
            return StringHelper.toKebabCase(s);
        });

        // Concat
        Handlebars.registerHelper("concat", function(a, b: string) {
            return a.concat(b);
        });

        // Equals
        Handlebars.registerHelper("eq", function(x: any, y: any, options: any) {
            if (x == y) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Not equal
        Handlebars.registerHelper("ne", function(x: any, y: any, options: any) {
            if (x != y) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Less than
        Handlebars.registerHelper("lt", function(x: any, y: any, options: any) {
            if (x < y) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Greater than
        Handlebars.registerHelper("gt", function(x: any, y: any, options: any) {
            if (x > y) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Addition
        Handlebars.registerHelper("add", function(x: number, y: number, options: any) {
            return x + y;
        });

        // Subtraction
        Handlebars.registerHelper("sub", function(x: number, y: number, options: any) {
            return x - y;
        });

        // Multiplication
        Handlebars.registerHelper("mul", function(x: number, y: number, options: any) {
            return x * y;
        });

        // Division
        Handlebars.registerHelper("div", function(x: number, y: number, options: any) {
            return x / y;
        });

        // Localization
        Handlebars.registerHelper("message", (key: string | any, ...args: any) => {
            // Empty message?
            if (!key) {
                return;
            }

            // Not string key means that key must be evaluated from tag's body
            if (typeof key != "string") {
                key = key.fn(this);
            }
            else {
                // Remove last arguments, hHandlebars context
                args.pop();
            }

            return Messages.get(key, ...args);
        });

        // Localization
        Handlebars.registerHelper("asValue", (v: any, ...args: any) => {
            // Empty value?
            if (v == null) {
                return null;
            }

            // { a: value, b: value } => "a,b"
            if (typeof v == "object") {
                return Object.values(v).join(", ");
            }
            // any => string
            else {
                return String(v);
            }
        });

        // asNumber
        Handlebars.registerHelper("asNumber", function(t: string, d: number, options: any) {
            return NumberHelper.toNumber(t, options ? d : 2);
        });

        // asMb
        Handlebars.registerHelper("asMb", function(n: number) {
            return NumberHelper.toMb(n);
        });

        // asDateTime
        Handlebars.registerHelper("asDateTime", function(t: string) {
            return DateHelper.toDateTimeString(t);
        });

        // asDateTime
        Handlebars.registerHelper("asShortDateTime", function(t: string) {
            return DateHelper.toShortDateTimeString(t);
        });

    }

}
