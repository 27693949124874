// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-detail {
  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  animation: keyframes-grow-in 300ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  position: absolute;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border-radius: 6px;
  z-index: 10;
}
pinko-detail > nav {
  padding: 32px 24px;
}
pinko-detail > article {
  flex-grow: 1;
  overflow: auto;
  padding: 24px 24px;
  margin: 0 auto;
  max-width: 720px;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-detail/pinko-detail.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAIA;EC+BE,aAAA;EACA,mBAAA;EAwDA,2CAAA;EAJA,oEAAA;EDhFA,kBAAA;EACA,sBAAA;EACA,yBEsDY;EFrDZ,kBAAA;EACA,WAAA;AAFF;AAIE;EACE,kBAAA;AAFJ;AAKE;EC+BA,YAAA;ED7BE,cAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;AAHJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
