// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `partner-detail {
  width: calc(100vw - 240px);
  height: calc(100vh - 120px);
}`, "",{"version":3,"sources":["webpack://./src/incinet/components/partner-detail/partner-detail.scss"],"names":[],"mappings":"AAIA;EACE,0BAAA;EACA,2BAAA;AAHF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
