// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/tools/24/24-tools-search.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/assets/images/icons/tools/24/24-tools-clear_filter.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-search {
  display: block;
  position: relative;
  margin-bottom: 12px;
}
pinko-search > label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
}
pinko-search > div.input {
  position: relative;
}
pinko-search > div.input input {
  font-family: "Incinet Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: border-color;
  display: block;
  width: 100%;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  color: #1F1F1F;
  background-color: #FFFFFF;
  padding: 8px 40px 8px 40px;
  margin: 4px 0;
  border: 1px solid rgba(111, 111, 111, 0.2);
  border-radius: 8px;
}
pinko-search > div.input input:focus {
  border-color: #8000F7;
}
pinko-search > div.input input::placeholder {
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-search > div.input input:-webkit-autofill, pinko-search > div.input input:-webkit-autofill:hover, pinko-search > div.input input:-webkit-autofill:focus, pinko-search > div.input input:-webkit-autofill:active {
  -webkit-text-fill-color: #1F1F1F;
  -webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;
}
pinko-search > div.input div.icon {
  content: "";
  position: absolute;
  top: 8px;
  width: 24px;
  height: 24px;
  background-color: rgba(22, 22, 22, 0.5019607843);
  mask-size: 24px 24px;
}
pinko-search > div.input div.icon-search {
  left: 12px;
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
pinko-search > div.input div.icon-clear {
  cursor: pointer !important;
  user-select: none;
  right: 12px;
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
pinko-search > div.description {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-search > div.reason {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  position: absolute;
  color: #FF1267;
}
pinko-search div.items {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  display: none;
  width: 100%;
  background-color: #FFFFFF;
  z-index: 120;
  border-radius: 6px;
  overflow: hidden;
  user-select: none;
}
pinko-search div.items div.item {
  display: flex;
  flex-direction: row;
  cursor: pointer !important;
  user-select: none;
  height: 40px;
  align-items: center;
  padding: 0 16px;
}
pinko-search div.items div.item:hover, pinko-search div.items div.item-selected {
  background-color: rgba(22, 22, 22, 0.0392156863);
}
pinko-search div.items div.item div.label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
}
pinko-search[focus=true] div.items {
  display: block;
}
pinko-search[empty=true] div.input div.icon-clear {
  display: none;
}
pinko-search[empty=true] div.items {
  display: none;
}
pinko-search[invalid=true] input {
  border-color: #FF1267;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-search/pinko-search.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss","webpack://./src/pinko/styles/_colors.scss","webpack://./src/pinko/styles/_positions.scss"],"names":[],"mappings":"AAKA;EACE,cAAA;EACA,kBAAA;EACA,mBAAA;AAJF;AAME;ECTA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EFKE,cGMiB;AHPrB;AAIE;EACE,kBAAA;AAFJ;AAII;ECbF,yCAAA;ECmGA,eAAA;EACA,kBAAA;EACA,mBAAA;EDvBA,uDAAA;EACA,iCAAA;ED/DI,cAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,cGRe;EHSf,yBGqCQ;EHpCR,0BAAA;EACA,aAAA;EACA,0CAAA;EACA,kBAAA;AAEN;AAAM;EACE,qBGoBmB;AHlB3B;AACM;EACE,qCGlBc;AHmBtB;AAEM;EAIE,gCG3Ba;EH4Bb,8CAAA;AAHR;AAOI;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,gDGxBgB;EHyBhB,oBAAA;AALN;AAOM;EACE,UAAA;EACA,mDAAA;AALR;AAQM;ECNJ,0BAAA;EACA,iBAAA;EDOM,WAAA;EACA,mDAAA;AALR;AAUE;ECvEA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EFmEE,qCGtDkB;AHiDtB;AAQE;EC5EA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EFwEE,kBAAA;EACA,cG/Ee;AH4EnB;AAME;ECSA,2CAAA;EDPE,kBAAA;EACA,aAAA;EACA,WAAA;EACA,yBGxBU;EHyBV,YItFW;EJuFX,kBAAA;EACA,gBAAA;EACA,iBAAA;AAJJ;AAMI;EC3DF,aAAA;EACA,mBAAA;EAsBA,0BAAA;EACA,iBAAA;EDsCI,YAAA;EACA,mBAAA;EACA,eAAA;AAFN;AAIM;EAEE,gDG/FM;AH4Fd;AAMM;ECzGJ,0CAAA;ECkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EFuFM,cG1Fa;AHyFrB;AAUI;EACE,cAAA;AARN;AAcM;EACE,aAAA;AAZR;AAgBI;EACE,aAAA;AAdN;AAmBI;EACE,qBGvIa;AHsHnB","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
