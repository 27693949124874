// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/fonts/ProximaNova-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/assets/fonts/ProximaNova-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/assets/fonts/ProximaNova-Semibold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  width: 100%;
  height: 100%;
}

body, h1, h2, h3, h4, h5, h6, p, ul, li, pre, button, input {
  padding: 0;
  margin: 0;
  border: 0;
}

h1, h2, h3, h4, h5, h6, th {
  font-weight: normal;
}

table {
  border-spacing: 0;
}

th {
  text-align: left;
}

ul, li {
  list-style: none;
}

label {
  display: block;
}

body {
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
}

a.link {
  color: #8000F7;
}

@font-face {
  font-family: "Incinet Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
}
@font-face {
  font-family: "Incinet Medium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
}
@font-face {
  font-family: "Incinet Semi Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff");
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb:window-inactive {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: #979797;
}

@keyframes keyframes-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes keyframes-slide-in {
  0% {
    opacity: 0;
    top: 49%;
  }
  100% {
    opacity: 1;
    top: 48%;
  }
}
@keyframes keyframes-grow-in {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes keyframes-shake {
  0% {
    transform: translate(-50%, -50%);
  }
  25% {
    transform: translate(-51%, -50%);
  }
  50% {
    transform: translate(-49%, -50%);
  }
  75% {
    transform: translate(-51%, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}`, "",{"version":3,"sources":["webpack://./src/pinko/styles/_reset.scss","webpack://./src/pinko/styles/index.scss","webpack://./src/pinko/styles/_globals.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss","webpack://./src/pinko/styles/_fonts.scss","webpack://./src/pinko/styles/_scrollbars.scss","webpack://./src/pinko/styles/_animations.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;ACAF;;ADIA;EACE,UAAA;EACA,SAAA;EACA,SAAA;ACDF;;ADKA;EACE,mBAAA;ACFF;;ADMA;EACE,iBAAA;ACHF;;ADOA;EACE,gBAAA;ACJF;;ADQA;EACE,gBAAA;ACLF;;ADSA;EACE,cAAA;ACNF;;AC1BA;ECqCE,aAAA;EACA,sBAAA;EDpCA,mCAAA;AD8BF;;AC3BA;EACE,cE6CyB;AHf3B;;AIvCA;EACE,8BAAA;EACA,2DAAA;AJ0CF;AIvCA;EACE,6BAAA;EACA,2DAAA;AJyCF;AItCA;EACE,gCAAA;EACA,2DAAA;AJwCF;AKnDA;EACE,UAAA;EACA,WAAA;ALqDF;;AKlDA;EACE,6BAAA;ALqDF;;AKlDA;EACE,6BAAA;EACA,kBAAA;ALqDF;;AKlDA;EACE,yBAAA;ALqDF;;AKlDA;EACE,6BAAA;ALqDF;;AKlDA;EACE,6BAAA;ALqDF;;AKlDA;EACE,yBAAA;ALqDF;;AMjFA;EACE;IAAK,UAAA;ENqFL;EMpFA;IAAO,UAAA;ENuFP;AACF;AMrFA;EACE;IACE,UAAA;IACA,QAAA;ENuFF;EMrFA;IACE,UAAA;IACA,QAAA;ENuFF;AACF;AMpFA;EACE;IACE,UAAA;IACA,qBAAA;ENsFF;EMpFA;IACE,UAAA;IACA,mBAAA;ENsFF;AACF;AMnFA;EACE;IACE,gCAAA;ENqFF;EMnFA;IACE,gCAAA;ENqFF;EMnFA;IACE,gCAAA;ENqFF;EMnFA;IACE,gCAAA;ENqFF;EMnFA;IACE,gCAAA;ENqFF;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
