// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-tabs {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
pinko-tabs div.item {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  cursor: pointer !important;
  user-select: none;
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: color;
  padding: 12px 0 10px 0;
  color: rgba(22, 22, 22, 0.5019607843);
  border-bottom: 2px solid transparent;
}
pinko-tabs div.item:hover {
  color: #1F1F1F;
}
pinko-tabs div.item-selected {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
  border-color: #1F1F1F;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-tabs/pinko-tabs.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAKA;EC8BE,aAAA;EACA,mBAAA;ED7BA,SAAA;EACA,mBAAA;AAHF;AAKE;ECDA,4CAAA;ECsKA,eAAA;EACA,kBAAA;EACA,mBAAA;EDvHA,0BAAA;EACA,iBAAA;EAwBA,uDAAA;EACA,0BAAA;EDtEE,sBAAA;EACA,qCGKkB;EHJlB,oCAAA;AAEJ;AAAI;EACE,cGDe;AHGrB;AACI;ECbF,4CAAA;ECsKA,eAAA;EACA,kBAAA;EACA,mBAAA;EFzJI,cGNe;EHOf,qBGPe;AHWrB","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
