import { Context } from "../../../hiyo/context.js";
import { PinkoFieldOptions } from "./types.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { FormatHelper } from "../../../hiyo/format-helper.js";
import { Log } from "../../../hiyo/log.js";
import { PinkoForm } from "../pinko-form/pinko-form.js";

export abstract class PinkoField<T extends Context = Context, U extends PinkoFieldOptions = PinkoFieldOptions> extends PinkoComponent<T, U> {

    // Event handling methods
    public onSubmit(): void {};
    public onClear(): void {};
    public onChange(value: any): void {};

    public setValue(value: any): void {
        // Sets value to options
        this.options.value = value;

        // Redraw
        this.render();
    }

    public setReason(reason: string): void {
        // Sets invalid and reason options
        this.options.invalid = (reason != null);
        this.options.reason = reason;

        // Redraw
        this.render();
    }

    public validate(): boolean {
        let reason = null;

        // Required and null?
        if (this.options.required && (this.options.value == null || this.options.value.length == 0)) {
            reason = "validation.valueRequired";
        }

        // Invalid number?
        if (this.options.format == "Number" && this.options.value?.length && !FormatHelper.isNumber(this.options.value)) {
            reason = "validation.invalidNumber";
        }

        // Invalid e-mail?
        if (this.options.format == "Email" && this.options.value?.length && !FormatHelper.isEmail(this.options.value)) {
            reason = "validation.invalidEmail";
        }

        // Invalid phone?
        if (this.options.format == "Phone" && this.options.value?.length  && !FormatHelper.isPhone(this.options.value)) {
            reason = "validation.invalidPhone";
        }

        // Invalid urk?
        if (this.options.format == "Url" && this.options.value?.length  && !FormatHelper.isUrl(this.options.value)) {
            reason = "validation.invalidUrl";
        }

        // Reason has changed?
        if (this.options.reason != reason) {
            // Set new reason and redraw
            this.setReason(reason);
        }

        if (this.options.reason) {
            Log.w(`${this.name}: Validation of ${this.options.name} failed (reason=${this.options.reason}, value=${this.options.value})`);
        }

        // Returns true if input passed the validation
        return (this.options.reason == null);
    }

    public submit(): void {
        // Blur to not stay focused anymore
        //this.querySelector("input")?.blur();

        // Submit pinko-form component as well if it is found as parent
        if (this.parentComponent instanceof PinkoForm) {
            this.parentComponent.submit();
        }

        // OnSubmit handler
        this.onSubmit();
    }
}