import "./partner-detail.scss";
import template from "./partner-detail.hbs";
import { component } from "../../../../hiyo/decorators.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { PinkoDetail } from "../../../../pinko/components/pinko-detail/pinko-detail.js";
import { PinkoSidebar } from "../../../../pinko/components/pinko-sidebar/pinko-sidebar.js";

@component(template)
export class PartnerDetail extends PinkoDetail<IncinetContext> {

    // Components
    public sidebar: PinkoSidebar;

    public onCreate() {
        // Create all components
        this.createSidebar();
    }

    public createSidebar(): void {
        // Create sidebar
        this.sidebar = new PinkoSidebar(this.context, {
            type: "Expanded",
            groups: [
                {
                    name: "General",
                    items: [
                        {
                            name: "Overview",
                            label: "labels.overview",
                            icon: "Cockpit"
                        },
                        {
                            name: "Offers",
                            label: "labels.offers",
                            icon: "Calculations"
                        },
                        {
                            name: "Contracts",
                            label: "labels.contracts",
                            icon: "Contracts"
                        },
                        {
                            name: "Details",
                            label: "labels.details",
                            icon: "Details",
                            selected: true
                        }
                    ]
                }
            ]
        });
    }


 }