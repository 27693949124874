import "./partner-preview.scss";
import template from "./partner-preview.hbs";
import { component } from "../../../hiyo/decorators.js";
import { IncinetContext } from "../../context/incinet-context.js";
import { PinkoPreview } from "../../../pinko/components/pinko-preview/pinko-preview.js";
import { PinkoTabs } from "../../../pinko/components/pinko-tabs/pinko-tabs.js";
import { PinkoConfirm } from "../../../pinko/components/pinko-confirm/pinko-confirm.js";
import { DropdownItem } from "../../../pinko/components/pinko-dropdown/types.js";
import { PartnerDetail } from "../partner-detail/partner-detail.js";

@component(template)
export class PartnerPreview extends PinkoPreview<IncinetContext> {

    // Components
    public tabs: PinkoTabs;

    public onCreate(): void {
        // Create tabs
        this.tabs = new PinkoTabs(this.context, {
            items: [
                {
                    name: "General",
                    label: "General",
                    selected: true
                },
                {
                    name: "Activity",
                    label: "Activity"
                }
            ]
        });
    }

    // Menu select
    public onSelect(item: DropdownItem): void {
        // Open detail?
        if (item.name == "Detail") {
            // Create detail
            let detail = new PartnerDetail(this.context, {
                data: this.options.data
            });

            // Show modal
            detail.showModal();
        }
    }

    public async delete(): Promise<void> {
        // Create confirm dialog
        let confirm = new PinkoConfirm(this.context, {
            title: "Really delete?",
            text: "Are you sure you want to delete?",
            labelConfirm: "labels.delete",
            escalated: true
        });

        // Delete on confirm
        confirm.onConfirm = async () => {
            // Lock component
            this.lock();

            // API call
            await this.context.api.deleteResource(`partners/${this.options.data._id}`);

            // Unlock component
            this.unlock();

            // Close self
            this.remove();

            // Tell listener there was an update
            this.onUpdate();
        }

        // Show modal
        confirm.showModal();
    }
}