import "./pinko-icon.scss";
import template from "./pinko-icon.hbs";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { Context } from "../../../hiyo/context.js";
import { PinkoIconOptions } from "./types.js";
import { component } from "../../../hiyo/decorators.js";

@component(template)
export class PinkoIcon extends PinkoComponent<Context, PinkoIconOptions> {

}