// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-browser, .pinko-browser {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  position: relative;
  box-sizing: border-box;
  background-color: #FFFFFF;
  padding: 24px;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/incinet/components/common/table-browser/table-browser.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAIA;EC6CE,YAAA;EATA,aAAA;EACA,sBAAA;EAuDA,mBAAA;EDxFA,kBAAA;EACA,sBAAA;EACA,yBEsDY;EFrDZ,aAAA;EACA,gBAAA;AAFF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
