// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/logo/20/invipo_logo_in_20_w.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main-menu {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
main-menu div.logo {
  cursor: pointer !important;
  user-select: none;
  width: 48px;
  height: 48px;
  background-color: rgba(22, 22, 22, 0.5019607843);
  margin: 12px 0;
  mask-position: 8px 14px;
  mask-size: 32px 20px;
  mask-repeat: no-repeat;
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
main-menu div.toolbar {
  padding: 8px 0;
}`, "",{"version":3,"sources":["webpack://./src/incinet/components/main-menu/main-menu.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAIA;EC6CE,YAAA;EATA,aAAA;EACA,sBAAA;ADrCF;AAIE;ECkDA,0BAAA;EACA,iBAAA;EDjDE,WAAA;EACA,YAAA;EACA,gDEoBkB;EFnBlB,cAAA;EACA,uBAAA;EACA,oBAAA;EACA,sBAAA;EACA,mDAAA;AADJ;AAIE;EACE,cAAA;AAFJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
