// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-confirm {
  width: 528px;
  min-height: 280px;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-confirm/pinko-confirm.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,iBAAA;AAFF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
