import { Accessor, ComponentContext, ComponentOptions } from "./types.js";
import { Context } from "./context.js";
import { SystemHelper } from "./system-helper.js";
import { Log } from "./log.js";
import { StringHelper } from "./string-helper.js";
import Handlebars from "handlebars";

export class Component<T extends ComponentContext = Context, U extends ComponentOptions = any> extends HTMLElement {

    // Properties
    public context: T;
    public options: U;
    public name: string;
    public template: any;
    public shouldLoad: boolean;
    public createdEmpty: boolean;
    public accessors: Accessor[];
    public events: string[];

    // Event handling methods
    public onCreate(): void {}; // Constructor was called
    public onAttach(): void {}; // Element was connected into DOM
    public onDetach(): void {}; // Element was disconnected from DOM
    public onRender(): void {}; // HTML is ready (used from DOM or rendered from template)
    public onEvent(event: Event): void {}; // Event through registered events[] was caught

    // Event handlers
    public readonly onEventHandler: (event: Event) => void;

    // Getters
    public get parentComponent(): Component {
        // Starting element for DOM search by excluding self
        let element: HTMLElement = this.parentElement;

        do {
            // Component found in DOM?
            if (element instanceof Component) {
                return <Component>element;
            }

            // Move up
            element = element.parentElement;
        }
        while (element);

        // No parent component found in DOM
        return null;
    }

    public constructor(context?: T, options?: U) {
        // Super call is mandatory because of web components
        super();

        // Assign name
        this.name = SystemHelper.newUid(this);

        // Assign context
        this.context = context;

        // Assign options or make empty object
        this.options = options || <U>{};

        // Create onEvent handler
        this.onEventHandler = (e: Event) => this.onEvent(e);

        // Copy all attributes to options expect of "id" that was already generated
        // This will overwrite option values already passed in constructor
        for (let name of this.getAttributeNames()) {
            if (name != "id") {
                // We must try to parse string attribute with type
                (<any>this.options)[name] = StringHelper.parseWithType(this.getAttribute(name));
            }
        }

        // Assign query properties from accessors array
        for (let a of this.accessors || []) {
            Object.defineProperty(this, a.property, {
                get() {
                    return this.querySelector(a.selector);
                }
            });
        }

        Log.d(`*Component ${this.name} created ${!this.parentElement ? "(manually)" : ""}`);

        // OnCreate handler
        this.onCreate();
    }

    protected async connectedCallback() {
        Log.d(`+Component ${this.name} attached`);

        // Assign context from parent component
        // We must do this as soon as element is connected
        this.context = this.context || <T>this.parentComponent?.context;

        // If markup was used to specify component HTML, we will flag it and never more use template within render()
        this.createdEmpty = (this.childNodes.length == 0);

        // Add event listener if subscribed
        if (this.events) {
            for (let event of this.events) {
                document.body.addEventListener(event, this.onEventHandler);
            }
        }

        // OnAttach handler
        this.onAttach();

        // Should component load?
        if (this.shouldLoad) {
            // Lock component to avoid user interaction
            this.lock();

            // Wait component to load, this can break render() chain
            await this.load();

            // Unlock component to avoid user interaction
            this.unlock();
        }

        // Render via template
        this.render();
    }

    protected async disconnectedCallback() {
        Log.d(`-Component ${this.name} detached`);

        // Remove event listener if subscribed
        if (this.events) {
            for (let event of this.events) {
                document.body.removeEventListener(event, this.onEventHandler);
            }
        }

        // OnDetach handler
        this.onDetach();
    }

    public render() {
        // If component was attached with empty nodes, we will use template to generate content
        if (this.createdEmpty) {
            // Empty and no template?
            if (!this.template) {
                Log.w(`${this.name}: Trying to render empty component or component without template. You need to put your HTML code in <${StringHelper.toKebabCase(this.constructor.name)}> tag or specify a template string in @component() decorator.`);
                return;
            }

            // Get Handlebars templating function on template string trimmed by additional whitespaces
            let template = Handlebars.compile(this.template.trim());

            // We create a new element in memory and attach a template HTML to it
            let wrapper = document.createElement("div");
            wrapper.innerHTML = template(this);

            // Template root element (expected to be <template>)
            let firstChild = <HTMLElement>wrapper.firstChild;

            // Invalid template?
            if (wrapper.childNodes.length != 1 || firstChild.tagName != "TEMPLATE") {
                Log.w(`${this.name}: Invalid template. Component template must start with <template> root element.`);
                return;
            }

            // Copy all attributes from <template> tag to new element
            for (let name of firstChild.getAttributeNames()) {
                this.setAttribute(name, firstChild.getAttribute(name));
            }

            // Assign template HTML to self
            this.innerHTML = firstChild.innerHTML;
        }

        // Propagate component instance to all DOM child nodes
        this.querySelectorAll("*").forEach((e: HTMLElement) => {
            // Because we propagate from top to down, we must NOT override already set
            // component instances from custom elements that were processed earlier
            if (!(<any>e).component) {
                (<any>e).component = this;
            }
        });

        // Inject components declared as properties
        this.querySelectorAll("inject-component").forEach((e: HTMLElement) => {
            // Component property name in class
            let property = e.getAttribute("property");

            // Has no property?
            if (!property) {
                Log.w(`Element <inject-component> has no property attribute`);
                return;
            }

            // No such property in class
            if (!this[property]) {
               Log.w(`Could not find component to inject ${this.constructor.name}.${property}`);
               return;
            }

            // If we forgot to clear components body, it will be rendered with already render template
            this[property].innerHTML = "";

            // Replace element with class property
            e.replaceWith(this[property]);
        });

        Log.d(`.Component ${this.name} rendered (${this.createdEmpty ? "from template" : "with child nodes"})`);

        // OnRender handler
        this.onRender();
    }

    public appendTo(element: HTMLElement): Component<T, U> {
        // Add to DOM
        return element.appendChild(this);
    }

    public clear(): void {
        // Clears already generated HTML inside element
        // This is necessary to invoke render() function back with template
        this.innerHTML = null;
    }

    public lock(): void {
        // Lock element for user interaction
        this.style.userSelect = "none";
        this.style.pointerEvents = "none";
    }

    public unlock(): void {
        // Unlock element from user interaction
        this.style.userSelect = null;
        this.style.pointerEvents = null;
    }

    public async load(): Promise<void> {
        Log.w(`${this.constructor.name}.load() not implemented. Forgot to override it?`);
    }

    public async reload(): Promise<void> {
        // Load only when it should load
        if (this.shouldLoad) {
            await this.load();
        }

        // And then redraw
        this.render();
    }

    public fire(type: string, data?: any): void {
        document.body.dispatchEvent(new CustomEvent(type, { detail: data }));
    }
}
