// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/v2/24/24-send.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/assets/images/icons/v2/24/24-plus.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-button {
  display: flex;
  flex-direction: row;
  cursor: pointer !important;
  user-select: none;
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: background-color;
  height: 40px;
  box-sizing: border-box;
  user-select: none;
  padding: 0 12px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
pinko-button div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}
pinko-button div.icon {
  width: 24px;
  height: 24px;
}
pinko-button div.icon-submit {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
pinko-button div.icon-add {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
pinko-button[type=Primary] {
  background-color: #8000F7;
}
pinko-button[type=Primary] div.label {
  color: #FFFFFF;
}
pinko-button[type=Primary] div.icon {
  background-color: #FFFFFF;
}
pinko-button[type=Tertiary] {
  border: 2px solid #8000F7;
}
pinko-button[type=Tertiary]:hover {
  background-color: rgba(22, 22, 22, 0.0392156863);
}
pinko-button[type=Tertiary] div.label {
  color: #8000F7;
}
pinko-button[type=Tertiary] div.icon {
  background-color: #8000F7;
}
pinko-button[type=Escalated] {
  background-color: #EB0D3F;
}
pinko-button[type=Escalated] div.label {
  color: #FFFFFF;
}
pinko-button[type=Escalated] div.icon {
  background-color: #FFFFFF;
}
pinko-button[width=Short] {
  width: 80px;
}
pinko-button[width=Medium] {
  width: 140px;
}
pinko-button[width=Long] {
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-button/pinko-button.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAKA;EC8BE,aAAA;EACA,mBAAA;EAsBA,0BAAA;EACA,iBAAA;EAwBA,uDAAA;EACA,qCAAA;ED3EA,YAAA;EACA,sBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;AADF;AAGE;ECTA,4CAAA;ECsKA,eAAA;EACA,kBAAA;EACA,mBAAA;AF5JF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AACI;EACE,mDAAA;AACN;AAEI;EACE,mDAAA;AAAN;AAIE;EACE,yBGVgB;AHQpB;AAII;EACE,cGhBgB;AHctB;AAKI;EACE,yBGpBgB;AHiBtB;AAOE;EACE,yBAAA;AALJ;AAOI;EACE,gDG3CQ;AHsCd;AAQI;EACE,cG7Bc;AHuBpB;AASI;EACE,yBGjCc;AH0BpB;AAWE;EACE,yBGPyB;AHF7B;AAWI;EACE,cG5CgB;AHmCtB;AAYI;EACE,yBGhDgB;AHsCtB;AAcE;EACE,WAAA;AAZJ;AAeE;EACE,YAAA;AAbJ;AAgBE;EACE,YAAA;AAdJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
