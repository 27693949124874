import "./pinko-preview.scss";
import { Context } from "../../../hiyo/context.js";
import { PinkoPreviewOptions } from "./types.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { PinkoDropdown } from "../pinko-dropdown/pinko-dropdown.js";
import { DropdownItem } from "../pinko-dropdown/types.js";
import { Log } from "../../../hiyo/log.js";

export abstract class PinkoPreview<T extends Context = Context, U extends PinkoPreviewOptions = PinkoPreviewOptions> extends PinkoComponent<T, U> {

    // Event handling methods
    public onUpdate(): void {}; // Called when something changes inside preview
    public onSelect(item: DropdownItem): void {}; // Dialog was submitted with result

    public select(pivot: HTMLElement): void {
        // Cancel bubbles
        event.stopPropagation();

        // Create overflow menu
        let menu = new PinkoDropdown(this.context, {
            anchor: "TopRight",
            start: "BottomRight",
            offset: [0, 2],
            stateless: true,
            groups: [
                {
                    name: "Main",
                    items: this.options.items
                }
            ]
        });

        // OnMenuSelect handler
        menu.onSelect = async (item: DropdownItem) => {
            // Delete item?
            if (item.name == "Delete") {
                // Call delete method
                await this.delete();
            }
            // Custom item
            else {
                // OnMenuSelect handler
                this.onSelect(item);
            }
        }

        // Show over menu button
        menu.show(pivot);
    }

    public async delete():Promise<void> {
        Log.w(`${this.constructor.name}.delete() not implemented. Forgot to override it?`);
    }

}