import "./offer-preview.scss";
import template from "./offer-preview.hbs";
import { component } from "../../../../hiyo/decorators.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { PinkoPreview } from "../../../../pinko/components/pinko-preview/pinko-preview.js";
import { PinkoTabs } from "../../../../pinko/components/pinko-tabs/pinko-tabs.js";

@component(template)
export class OfferPreview extends PinkoPreview<IncinetContext> {

    // Components
    public tabs: PinkoTabs;

    public onCreate(): void {
        // Create tabs
        this.tabs = new PinkoTabs(this.context, {
            items: [
                {
                    name: "General",
                    label: "General",
                    selected: true
                },
                {
                    name: "Activity",
                    label: "Activity"
                }
            ]
        });
    }

}