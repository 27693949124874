// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/v2/16/16-close.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/assets/images/icons/v2/16/16-caret-down.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-tag {
  display: flex;
  flex-direction: row;
  cursor: pointer !important;
  user-select: none;
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: background-color;
  height: 28px;
  box-sizing: border-box;
  align-items: center;
  padding: 0 8px;
  border-radius: 8px;
}
pinko-tag div.label {
  font-family: "Incinet Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  white-space: nowrap;
}
pinko-tag div.icon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}
pinko-tag[icon=Close] div.icon {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
pinko-tag[icon=Down] div.icon {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
pinko-tag[type=Default] {
  background-color: rgba(38, 38, 38, 0.1019607843);
}
pinko-tag[type=Default]:hover {
  background-color: rgba(141, 141, 141, 0.4);
}
pinko-tag[type=Default] div.label {
  color: #1F1F1F;
}
pinko-tag[type=Default] div.icon {
  background-color: rgba(22, 22, 22, 0.5019607843);
}
pinko-tag[type=Color] {
  background-color: #8000F7;
}
pinko-tag[type=Color] div.label {
  color: #FFFFFF;
}
pinko-tag[type=Color] div.icon {
  background-color: rgba(255, 255, 255, 0.6);
}
pinko-tag[type=Inverse] {
  background-color: #393939;
}
pinko-tag[type=Inverse] div.label {
  color: #FFFFFF;
}
pinko-tag[type=Inverse] div.icon {
  background-color: rgba(255, 255, 255, 0.6);
}
pinko-tag[size=Small] {
  height: 24px;
  padding: 0 6px;
}
pinko-tag[size=Small] div.label {
  font-family: "Incinet Medium", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-tag/pinko-tag.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAIA;EC+BE,aAAA;EACA,mBAAA;EAsBA,0BAAA;EACA,iBAAA;EAwBA,uDAAA;EACA,qCAAA;ED5EA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;AAAF;AAEE;ECTA,yCAAA;EC4FA,eAAA;EACA,kBAAA;EACA,mBAAA;EFnFE,mBAAA;AAGJ;AAAE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AAEJ;AAEI;EACE,mDAAA;AAAN;AAKI;EACE,mDAAA;AAHN;AAOE;EACE,gDGsBmB;AH3BvB;AAOI;EACE,0CGhBgB;AHWtB;AAQI;EACE,cG3Be;AHqBrB;AASI;EACE,gDGjBgB;AHUtB;AAWE;EACE,yBAAA;AATJ;AAWI;EACE,cGlCgB;AHyBtB;AAYI;EACE,0CGxB0B;AHchC;AAcE;EACE,yBGLmB;AHPvB;AAcI;EACE,cGhDe;AHoCrB;AAeI;EACE,0CGtCyB;AHyB/B;AAiBE;EACE,YAAA;EACA,cAAA;AAfJ;AAiBI;EC5EF,yCAAA;EC8EA,eAAA;EACA,kBAAA;EACA,mBAAA;AFfF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
