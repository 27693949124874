import "./main-menu.scss";
import template from "./main-menu.hbs";
import { component } from "../../../../hiyo/decorators.js";
import { Component } from "../../../../hiyo/component.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { PinkoSidebar } from "../../../../pinko/components/pinko-sidebar/pinko-sidebar.js";
import { SidebarItem } from "../../../../pinko/components/pinko-sidebar/types";
import { StringHelper } from "../../../../hiyo/string-helper.js";
import { PinkoDropdown } from "../../../../pinko/components/pinko-dropdown/pinko-dropdown.js";
import { DropdownItem } from "../../../../pinko/components/pinko-dropdown/types.js";
import { FormDialog } from "../form-dialog/form-dialog.js";
import { PartnerFormDialog } from "../../partner/partner-form-dialog/partner-form-dialog.js";
import { OfferFormDialog } from "../../offer/offer-form-dialog/offer-form-dialog.js";

const KEY_TOGGLED = "Incinet.MainMenu.toggled";
const DIALOG_NEW_MAP = {
    "NewPartner": PartnerFormDialog,
    "NewOffer": OfferFormDialog
}

@component(template)
export class MainMenu extends Component<IncinetContext> {

    // Properties
    public toggled: boolean;

    // Components
    public sidebar: PinkoSidebar;

    public onCreate() {
        // Restore toggled from storage
        this.toggled = localStorage.getItem(KEY_TOGGLED)?.toLowerCase() == "true";

        // Create all components
        this.createSidebar();
    }

    public createSidebar(): void {
        // Create sidebar
        this.sidebar = new PinkoSidebar(this.context, {
            type: this.toggled ? "Expanded" : "Collapsed",
            groups: [
                {
                    name: "Shortcuts",
                    items: [
                        {
                            name: "New",
                            label: "components.MainMenu.new",
                            icon: "New",
                            escalated: true,
                            readonly: true,
                            separated: true
                        }
                    ]
                },
                {
                    name: "Insights",
                    items: [
                        {
                            name: "Toggle",
                            label: "components.MainMenu.hide",
                            icon: "Toggle",
                            readonly: true
                        },
                        {
                            name: "Cockpit",
                            label: "components.MainMenu.cockpit",
                            icon: "Cockpit",
                            separated: true
                        }
                    ]
                },
                {
                    name: "Company",
                    //label: "Company",
                    items: [
                        {
                            name: "PartnerBrowser",
                            label: "components.MainMenu.partners",
                            icon: "Partners"
                        },
                        {
                            name: "OfferBrowser",
                            label: "components.MainMenu.offers",
                            icon: "Offers",
                        },
                        {
                            name: "ContractBrowser",
                            label: "components.MainMenu.contracts",
                            icon: "Contracts"
                        },
                        {
                            name: "CalculationBrowser",
                            label: "components.MainMenu.calculations",
                            icon: "Calculations",
                            separated: true
                        }
                    ]
                },
                {
                    name: "Management",
                    items: [
                        {
                            name: "TaskBrowser",
                            label: "components.MainMenu.tasks",
                            icon: "Tasks",
                            separated: true
                        }
                    ]
                },
                {
                    name: "Settings",
                    items: [
                        {
                            name: "UserBrowser",
                            label: "components.MainMenu.users",
                            icon: "Users"
                        },
                        {
                            name: "Logout",
                            label: "components.MainMenu.logout",
                            icon: "Logout"
                        }
                    ]
                }
            ]
        });

        // Set content
        this.sidebar.onSelect = (item: SidebarItem) => {
            // New?
            if (item.name == "New") {
                this.select();
                return;
            }

            // Toggle?
            if (item.name == "Toggle") {
                this.toggle();
                return;
            }

            // Logout?
            if (item.name == "Logout") {
                this.fire("logout");
                return;
            }

            // Get component name
            let component = StringHelper.toKebabCase(item.name);

            // Add component to page
            document.body.querySelector("incinet-app > main").innerHTML = `<${component} class="pinko-browser"></${component}>`;
        }
    }

    public onRender() {
        // Auto select
        this.sidebar.select("PartnerBrowser");
    }

    public select() {
        // Stop event propagation to item click
        event.stopPropagation();

        // Create shortcut menu
        let shortcuts = new PinkoDropdown(this.context, {
            anchor: "TopLeft",
            start: "TopRight",
            offset: [4, 0],
            groups: [
                {
                    name: "Main",
                    items: [
                        {
                            name: "NewPartner",
                            label: "components.MainMenu.newPartner"
                        },
                        {
                            name: "NewOffer",
                            label: "components.MainMenu.newOffer"
                        }
                    ]
                }
            ]
        })

        // Open shortcut dialog
        shortcuts.onSelect = (item: DropdownItem) => {
            // Create dialog from map
            let dialog: FormDialog = new DIALOG_NEW_MAP[item.name](this.context);

            // Show modal
            dialog.showModal();
        }

        // Show dropdown
        shortcuts.show(this.querySelector("div.item-new"));
    }

    public toggle(): void {
        // Chane toggle
        this.toggled = !this.toggled;

        // Save to storage
        localStorage.setItem(KEY_TOGGLED, this.toggled.toString())

        // Toggle sidebar
        this.sidebar.toggle();
    }

}