export const LOCALE = localStorage.getItem("hiyo.langId") || navigator.language;

const REGEXP_NUMBER = /^-?[0-9,\.]+$/;
const REGEXP_EMAIL =  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
const REGEXP_PHONE = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;
const REGEXP_URL = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

export class FormatHelper {

    public static isNumber(value: string): boolean {
        return value.match(REGEXP_NUMBER) != null;
    }

    public static isEmail(value: string): boolean {
        return value.match(REGEXP_EMAIL) != null;
    }

    public static isPhone(value: string): boolean {
        return value.match(REGEXP_PHONE) != null;
    }

    public static isUrl(value: string): boolean {
        return value.match(REGEXP_URL) != null;
    }

}
