// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-preview, offer-preview {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  width: 520px;
  position: relative;
  box-sizing: border-box;
  background-color: #FFFFFF;
  overflow: hidden;
}
pinko-preview > header, offer-preview > header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 24px 16px 24px;
}
pinko-preview > header div.title div.label, offer-preview > header div.title div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
}
pinko-preview > header div.title div.description, offer-preview > header div.title div.description {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
  margin-top: 4px;
}
pinko-preview > header div.toolbar, offer-preview > header div.toolbar {
  display: flex;
  flex-direction: row;
}
pinko-preview > nav, offer-preview > nav {
  padding: 0 24px 16px 24px;
}
pinko-preview > article, offer-preview > article {
  flex-grow: 1;
  overflow: auto;
  padding: 0 24px;
}
pinko-preview > article div.activities, offer-preview > article div.activities {
  margin-bottom: 32px;
}
pinko-preview > article div.activities div.period, offer-preview > article div.activities div.period {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: rgba(31, 31, 31, 0.6980392157);
  padding: 12px 0 8px 0;
  text-align: center;
}
pinko-preview > article div.activities div.activity, offer-preview > article div.activities div.activity {
  max-width: 64%;
  margin-bottom: 4px;
}
pinko-preview > article div.activities div.activity div.user, offer-preview > article div.activities div.activity div.user {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: rgba(31, 31, 31, 0.6980392157);
  padding: 8px 0 4px 0;
}
pinko-preview > article div.activities div.activity p.text, offer-preview > article div.activities div.activity p.text {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  padding: 8px 12px;
  border-radius: 8px;
}
pinko-preview > article div.activities div.activity p.text span.timestamp, offer-preview > article div.activities div.activity p.text span.timestamp {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  display: block;
  color: rgba(31, 31, 31, 0.6980392157);
  text-align: right;
  margin-top: 4px;
}
pinko-preview > article div.activities div.activity-user, offer-preview > article div.activities div.activity-user {
  margin-left: auto;
}
pinko-preview > article div.activities div.activity-user div.user, offer-preview > article div.activities div.activity-user div.user {
  text-align: right;
}
pinko-preview > article div.activities div.activity-user p.text, offer-preview > article div.activities div.activity-user p.text {
  color: #1F1F1F;
  background-color: rgba(22, 22, 22, 0.0392156863);
}
pinko-preview > article div.activities div.activity-system, offer-preview > article div.activities div.activity-system {
  margin-right: auto;
}
pinko-preview > article div.activities div.activity-system div.user, offer-preview > article div.activities div.activity-system div.user {
  text-align: left;
}
pinko-preview > article div.activities div.activity-system p.text, offer-preview > article div.activities div.activity-system p.text {
  color: #1F1F1F;
  background-color: rgba(128, 0, 247, 0.3019607843);
}
pinko-preview > article div.submit, offer-preview > article div.submit {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
pinko-preview > footer, offer-preview > footer {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 24px;
}
@media only screen and (max-width: 1280px) {
  pinko-preview, offer-preview {
    width: 352px;
  }
}
@media only screen and (max-width: 1112px) {
  pinko-preview, offer-preview {
    width: 352px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-preview/pinko-preview.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss","webpack://./src/incinet/components/offer/offer-preview/offer-preview.scss","webpack://./src/pinko/styles/_styles.scss"],"names":[],"mappings":"AAIA;EC6CE,YAAA;EATA,aAAA;EACA,sBAAA;EAuDA,mBAAA;EDxFA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,yBEqDY;EFpDZ,gBAAA;AGFF;AHIE;ECqBA,aAAA;EACA,mBAAA;EDpBE,8BAAA;EACA,uBAAA;EACA,4BAAA;AGDJ;AHIM;ECZJ,4CAAA;EG2LA,eAAA;EACA,kBAAA;EACA,mBAAA;EJ/KM,cELa;ACMrB;AHEM;ECzBJ,0CAAA;EGkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EJOM,qCERc;EFSd,eAAA;AGGR;AHCI;ECEF,aAAA;EACA,mBAAA;AEAF;AHEE;EACE,yBAAA;AGAJ;AHGE;ECOA,YAAA;EDLE,cAAA;EACA,eAAA;AGDJ;AHGI;EACE,mBAAA;AGDN;AHGM;ECjDJ,0CAAA;EGIA,eAAA;EACA,kBAAA;EACA,mBAAA;EJ6CM,qCEjCe;EFkCf,qBAAA;EACA,kBAAA;AGER;AHCM;EACE,cAAA;EACA,kBAAA;AGCR;AHCQ;EC5DN,0CAAA;EGIA,eAAA;EACA,kBAAA;EACA,mBAAA;EJwDQ,qCE5Ca;EF6Cb,oBAAA;AGIV;AHDQ;EClEN,0CAAA;EGkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EJgDQ,iBAAA;EACA,kBAAA;AGMV;AHJU;ECvER,0CAAA;EGIA,eAAA;EACA,kBAAA;EACA,mBAAA;EJmEU,cAAA;EACA,qCExDW;EFyDX,iBAAA;EACA,eAAA;AGSZ;AHLQ;EACE,iBAAA;AGOV;AHLU;EACE,iBAAA;AGOZ;AHJU;EACE,cEvES;EFwET,gDElFE;ACwFd;AHFQ;EACE,kBAAA;AGIV;AHFU;EACE,gBAAA;AGIZ;AHDU;EACE,cEpFS;EFqFT,iDEzFE;AC4Fd;AHGI;EC1EF,aAAA;EACA,mBAAA;ED2EI,QAAA;AGAN;AHIE;EChFA,aAAA;EACA,mBAAA;EDiFE,QAAA;EACA,aAAA;AGDJ;AHIE;EArHF;IAsHI,YAAA;EGDF;AACF;AHGE;EAzHF;IA0HI,YAAA;EGAF;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
