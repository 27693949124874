import "./partner-select.scss";
import { PinkoSelect } from "../../../../pinko/components/pinko-select/pinko-select.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { component } from "../../../../hiyo/decorators.js";

@component(null)
export class PartnerSelect extends PinkoSelect<IncinetContext> {

    public async onRender(): Promise<void> {
        // Items already loaded?
        if (this.options.items?.length) {
            return;
        }

        // Get partners list
        let partners = await this.context.api.getResource("partners?pageSize=0");

        // Clear items
        this.options.items = [];

        for (let partner of partners.data) {
            this.options.items.push({
                name: partner._id,
                label: partner.name,
                selected: (this.options.value && Object.keys(this.options.value).includes(partner._id))
            });
        }

    }

}