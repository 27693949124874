// General stylesheets are imported here.
// Beware of all SASS code that generates CSS output only ONCE nad here.
import "pinko/styles/index.scss";

import "./incinet-app.scss";
import template from "./incinet-app.hbs";
import messagesEn from "../../messages/messages.en.json";
import { component, events } from "../../../hiyo/decorators.js";
import { Component } from "../../../hiyo/component.js";
import { IncinetContext } from "../../context/incinet-context.js";
import { User } from "../../clients/api-client/types.js";
import { Log } from "../../../hiyo/log.js";
import { Messages } from "../../../hiyo/messages.js";
import { Templates } from "../../../hiyo/templates.js";

const USER_TOKEN_KEY = "Hiyo.userToken";

@component(template, true)
@events("login", "logout")
export class IncinetApp extends Component<IncinetContext> {

    // Properties
    public user: User;

    public async onCreate() {
        Log.i("Welcome to Incinet. The world of fantasy🤡");

        // Add localization
        Messages.add("en", messagesEn);
        Messages.add("cs", messagesEn);
        Messages.guessLang();

        // Register Handlebars helpers
        Templates.registerHelpers();

        // Incinet app component must create an instance of IncinetContext that
        // will be shared across all components
        this.context = new IncinetContext();
        Log.i(`${this.constructor.name}: Application context created`);

        // Put application instance (this) as a global window.app reference for better debugging
        (<any>window).app = this;
    }

    public onEvent(event: Event) {
        // User logged in?
        if (event.type == "login") {
            Log.i(`${this.constructor.name}: Received a "login" event, will proceed to enter the app`);

            // Assign user
            this.user = (<CustomEvent>event).detail;

            // Store user token to local storage
            localStorage.setItem(USER_TOKEN_KEY, this.user.token);

            // Set store token
            this.context.api.enableBasicAuthorization(this.user.token);

            // Redraw
            this.render();
        }

        // User logged out?
        if (event.type == "logout") {
            // Delete user
            this.user = null;

            // Remove stored user token
            localStorage.removeItem(USER_TOKEN_KEY);

            // Redraw
            this.render();
        }
    }

    public async load(): Promise<any> {
        // Get user token stored in local storage
        let token = localStorage.getItem(USER_TOKEN_KEY);

        // Token found, so we need to verify its validity
        if (token) {
            // Set store token
            this.context.api.enableBasicAuthorization(token);

            // Verify user using store token
            try {
                this.user = await this.context.api.getLoggedUser();
                Log.i(`${this.constructor.name}: User ${this.user.name} restored (stored token still valid)`);
            }
            catch (e) {
                Log.i(`User token is invalid or has expired`);
                console.info(e);
            }
        }

        // We need user to log in
        if (!this.user) {
            Log.i(`User token not found or expired, will need to login again`);
        }
    }

}