// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes keyframes-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes keyframes-slide-in {
  0% {
    opacity: 0;
    top: 49%;
  }
  100% {
    opacity: 1;
    top: 48%;
  }
}
@keyframes keyframes-grow-in {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes keyframes-shake {
  0% {
    transform: translate(-50%, -50%);
  }
  25% {
    transform: translate(-51%, -50%);
  }
  50% {
    transform: translate(-49%, -50%);
  }
  75% {
    transform: translate(-51%, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
pinko-dialog, pinko-confirm {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  animation: keyframes-grow-in 300ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  position: absolute;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border-radius: 6px;
  z-index: 10;
}
pinko-dialog > header, pinko-confirm > header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 24px 16px 24px;
}
pinko-dialog > header div.title div.label, pinko-confirm > header div.title div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
}
pinko-dialog > header div.title div.description, pinko-confirm > header div.title div.description {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
  margin-top: 4px;
}
pinko-dialog > header div.toolbar, pinko-confirm > header div.toolbar {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}
pinko-dialog > nav, pinko-confirm > nav {
  padding: 0 24px;
}
pinko-dialog > article, pinko-confirm > article {
  flex-grow: 1;
  overflow: auto;
  padding: 0 24px;
}
pinko-dialog > article p.description, pinko-confirm > article p.description {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
  margin-bottom: 16px;
}
pinko-dialog > footer, pinko-confirm > footer {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 4px;
  padding: 24px;
}

pinko-confirm {
  width: 528px;
  min-height: 280px;
}`, "",{"version":3,"sources":["webpack://./src/pinko/styles/_animations.scss","webpack://./src/pinko/components/pinko-confirm/pinko-confirm.scss","webpack://./src/pinko/components/pinko-dialog/pinko-dialog.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss","webpack://./src/pinko/styles/_styles.scss"],"names":[],"mappings":"AAAA;EACE;IAAK,UAAA;ECEL;EDDA;IAAO,UAAA;ECIP;AACF;ADFA;EACE;IACE,UAAA;IACA,QAAA;ECIF;EDFA;IACE,UAAA;IACA,QAAA;ECIF;AACF;ADDA;EACE;IACE,UAAA;IACA,qBAAA;ECGF;EDDA;IACE,UAAA;IACA,mBAAA;ECGF;AACF;ADAA;EACE;IACE,gCAAA;ECEF;EDAA;IACE,gCAAA;ECEF;EDAA;IACE,gCAAA;ECEF;EDAA;IACE,gCAAA;ECEF;EDAA;IACE,gCAAA;ECEF;AACF;ACvCA;ECmCE,aAAA;EACA,sBAAA;EAmDA,2CAAA;EAJA,oEAAA;ED/EA,kBAAA;EACA,sBAAA;EACA,yBEqDY;EFpDZ,kBAAA;EACA,WAAA;AD0CF;ACxCE;ECoBA,aAAA;EACA,mBAAA;EDnBE,8BAAA;EACA,uBAAA;EACA,4BAAA;AD2CJ;ACxCM;ECbJ,4CAAA;EE2LA,eAAA;EACA,kBAAA;EACA,mBAAA;EH9KM,cENa;AHmDrB;AC1CM;EC1BJ,0CAAA;EEkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EHQM,qCETc;EFUd,eAAA;AD+CR;AC3CI;ECCF,aAAA;EACA,mBAAA;EDAI,iBAAA;AD8CN;AC1CE;EACE,eAAA;AD4CJ;ACzCE;ECKA,YAAA;EDHE,cAAA;EACA,eAAA;AD2CJ;ACxCI;ECjDF,0CAAA;EEkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EH+BI,cElCe;EFmCf,mBAAA;AD6CN;ACzCE;ECtBA,aAAA;EACA,mBAAA;EDuBE,oBAAA;EACA,QAAA;EACA,aAAA;AD4CJ;;AArGA;EAEE,YAAA;EACA,iBAAA;AAuGF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
