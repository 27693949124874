import "./pinko-search.scss";
import template from "./pinko-search.hbs";
import { Context } from "../../../hiyo/context.js";
import { PinkoSearchOptions, SearchItem } from "./types.js";
import { component } from "../../../hiyo/decorators.js";
import { PinkoField } from "../pinko-field/pinko-field.js";

const ATTRIBUTE_FOCUS = "focus";
const ATTRIBUTE_EMPTY = "empty";

@component(template)
export class PinkoSearch extends PinkoField<Context, PinkoSearchOptions> {

    // Properties
    public items: SearchItem[];
    public index: number;

    // Event handling methods
    public onSelect(item: SearchItem): void {};

    public focus() {
        // Toggle focus attribute
        this.setAttribute(ATTRIBUTE_FOCUS, "true");
    }

    public blur() {
        // Get related target
        let target = <HTMLElement>(<MouseEvent>event).relatedTarget;

        // Outside of item was clicked
        if (target?.className != "item") {
            // Toggle focus attribute
            this.setAttribute(ATTRIBUTE_FOCUS, "false");
        }
    }

    public async keyUp(key: number) {
        // Empty string fix
        if (this.options.value == "") {
            this.options.value = null;
        }

        // Read value
        this.options.value = this.querySelector<HTMLInputElement>("input").value;

        // Set empty attribute
        this.setAttribute(ATTRIBUTE_EMPTY, (this.options.value?.length) ? "false" : "true");

        // Arrow keys are handled on down event
        if (key == 38 || key == 40) {
            return;
        }

        // Enter
        if (key == 13) {
            // Item selected?
            if (this.index > 0) {
                // Blur select
                this.querySelector("input").blur();

                // OnSelect handler
                this.onSelect(this.items[this.index -1]);
            }
            else {
                this.submit();
            }
            return;
        }

        // ESC
        if (key == 27) {
            // Stop propagation to prevent closing details etc.
            event.stopPropagation();

            // Close
            await this.clear();
            return;
        }

        // Search for match
        await this.search(this.options.value);
    }

    public keyDown(key: number) {
        // Arrow up
        if (key == 38) {
            // Stop propagation to prevent caret moving
            event.preventDefault();
            event.stopPropagation();

            // Select next item
            this.move(-1);
            return;
        }

        // Arrow down
        if (key == 40) {
            // Stop propagation to prevent caret moving
            event.preventDefault();
            event.stopPropagation();

            // Select next item
            this.move(1);
            return;
        }
    }

    public move(step: number): void {
        // Not possible to move?
        if (!this.querySelector(`div.items div[tabindex="${this.index + step}"]`)) {
            return;
        }

        // Get next element
        let previous = this.querySelector(`div.items div[tabindex="${this.index}"]`);

        // Next index
        this.index += step;

        let next = this.querySelector(`div.items div[tabindex="${this.index}"]`);

        // Update classes
        previous?.classList.remove("item-selected");
        next.classList.add("item-selected");
    }

    public async clear(close?: boolean): Promise<void> {
        // Clear input
        this.querySelector<HTMLInputElement>("input").value = null;

        // Clear value
        this.options.value = null;

        // Set empty attribute
        this.setAttribute(ATTRIBUTE_EMPTY, "true");

        // Reset searched results
        await this.search(null);

        // Submit
        this.submit();
    }

    public async search(term: string): Promise<void> {
        // User resolver to load items
        this.items = await this.options.resolver(term);

        // Reset cursor index
        this.index = 0;

        // HTML result
        let html = "";

        // Build areas
        if (this.items?.length) {
            for (let i = 0; i < this.items.length && i < 5; i++) {
                html += `<div class="item" tabindex="${i + 1}">`;
                html += `    <div class="name">`;
                html += `        <div class="label">${this.items[i].label}</div>`;
                html += `    </div>`;
                html += `</div>`;
            }
        }

        // Set items HTML to container
        this.querySelector("div.items").innerHTML = html;

        // Assign click action
        this.querySelectorAll<HTMLElement>("div.items div.item").forEach(x => {
            // Add on click action
            x.addEventListener("click", () => {
                // We must toggle focus afterward
                this.setAttribute(ATTRIBUTE_FOCUS, "false");

                // OnSelect handler
                this.onSelect(this.items[Number(x.getAttribute("tabindex")) - 1]);
            });
        });
    }
}