import "./offer-form-dialog.scss";
import template from "./offer-form-dialog.hbs";
import { component } from "../../../../hiyo/decorators.js";
import { Offer } from "../../../clients/api-client/types.js";
import { FormDialog } from "../../common/form-dialog/form-dialog.js";
import { OfferModuleDialog } from "../../offer/offer-module-dialog/offer-module-dialog.js";

@component(template)
export class OfferFormDialog extends FormDialog {

    // Properties
    public offer: Offer = null;

    public onAttach(): void {
        // Set data or empty project to offer
        this.offer = this.options.data || {
            _id: null,
            expiry: null,
            number: null,
            title: null,
            items: [],
            summary: {
                licenses: null,
                delivery: null,
                maintenance: null,
                total: null,
                discount: null,
                offerTotal: null,
            },
            termsAndConditions: null,
            note: null
        };
    }

    public addModule(): void {
        // Module dialog
        let dialog = new OfferModuleDialog(this.context);

        // Handle data
        dialog.onSubmit = (module: any) => {
            console.info(module);

            // Add new module
            this.offer.items.push(module);

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public removeModule(i: number): void {
        // Cancel bubbles
        event.stopPropagation();

        // Remove module by index
        this.offer.items.splice(i, 1);

        // Redraw
        this.render();
    }

    public async trySubmit(){
        console.info(this.form.getData());
        return;

        // Merge offer data with form
        this.offer = {
            ...this.offer,
            ...this.form.getData()
        }

        // Update call?
        if (this.offer._id) {
            await this.context.api.updateResource(`offers/${this.offer._id}`, this.offer);
        }
        // Create call
        else {
            await this.context.api.createResource("offers", this.offer);
        }
    }

}