// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-dropdown {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  min-width: 256px;
  max-height: 400px;
  background-color: #FFFFFF;
  z-index: 120;
  border-radius: 6px;
  overflow: auto;
  user-select: none;
  animation: keyframes-overflow-menu-slide-in 150ms;
}
pinko-dropdown div.group {
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;
  padding: 0 16px;
}
pinko-dropdown div.group div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-dropdown div.item {
  display: flex;
  flex-direction: row;
  cursor: pointer !important;
  user-select: none;
  height: 40px;
  align-items: center;
  padding: 0 16px;
}
pinko-dropdown div.item:hover {
  background-color: rgba(22, 22, 22, 0.0392156863);
}
pinko-dropdown div.item div.label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
}
pinko-dropdown div.item-escalated:hover {
  background-color: #EB0D3F !important;
}
pinko-dropdown div.item-escalated:hover div.label {
  color: #FFFFFF;
}
pinko-dropdown div.item-selected {
  background-color: rgba(128, 0, 247, 0.3019607843) !important;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-dropdown/pinko-dropdown.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss","webpack://./src/pinko/styles/_positions.scss","webpack://./src/pinko/styles/_styles.scss"],"names":[],"mappings":"AAKA;ECuFE,2CAAA;EDrFA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBEsDY;EFrDZ,YGRa;EHSb,kBAAA;EACA,cAAA;EACA,iBAAA;EACA,iDAAA;AAJF;AAME;ECkBA,aAAA;EACA,mBAAA;EDjBE,YAAA;EACA,mBAAA;EACA,eAAA;AAHJ;AAKI;ECdF,4CAAA;EGsKA,eAAA;EACA,kBAAA;EACA,mBAAA;EJxJI,qCELgB;AFKtB;AAIE;ECMA,aAAA;EACA,mBAAA;EAsBA,0BAAA;EACA,iBAAA;ED3BE,YAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AAEI;EACE,gDE7BQ;AF6Bd;AAGI;ECvCF,0CAAA;EGkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EJqBI,cExBe;AF0BrB;AAEM;EACE,oCAAA;AAAR;AAEQ;EACE,cE3BY;AF2BtB;AAKI;EACE,4DAAA;AAHN","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
