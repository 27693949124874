// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-action {
  cursor: pointer !important;
  user-select: none;
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  display: inline-block;
  color: #8000F7;
  padding: 0 4px;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-action/pinko-action.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAIA;ECsDE,0BAAA;EACA,iBAAA;EAlDA,4CAAA;ECsKA,eAAA;EACA,kBAAA;EACA,mBAAA;EF1KA,qBAAA;EACA,cGkBkB;EHjBlB,cAAA;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
