import "./pinko-preview.scss";
import { Context } from "../../../hiyo/context.js";
import { PinkoPreviewOptions } from "./types.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { DropdownItem } from "../pinko-dropdown/types.js";

export abstract class PinkoPreview<T extends Context = Context, U extends PinkoPreviewOptions = PinkoPreviewOptions> extends PinkoComponent<T, U> {

    // Event handling methods
    public onUpdate(): void {}; // Called when something changes inside preview
    public onSelect(item: DropdownItem): void {}; // Dialog was submitted with result

}