// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `partner-form-dialog {
  width: 996px !important;
  height: calc(100vh - 240px);
}
@media only screen and (max-width: 1280px) {
  partner-form-dialog {
    height: calc(100vh - 120px);
  }
}`, "",{"version":3,"sources":["webpack://./src/incinet/components/partner-form-dialog/partner-form-dialog.scss"],"names":[],"mappings":"AAGA;EACE,uBAAA;EACA,2BAAA;AAFF;AAIE;EAJF;IAKI,2BAAA;EADF;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
