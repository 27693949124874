import "./pinko-sidebar.scss";
import template from "./pinko-sidebar.hbs";
import { component, events } from "../../../hiyo/decorators.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { Context } from "../../../hiyo/context.js";
import { SidebarGroup, SidebarItem, PinkoSidebarOptions } from "./types.js";
import { Log } from "../../../hiyo/log.js";
import { StringHelper } from "../../../hiyo/string-helper.js";

@component(template)
@events("click")
export class PinkoSidebar extends PinkoComponent<Context, PinkoSidebarOptions> {

    // Event handling methods
    public onSelect(item: SidebarItem, group?: SidebarGroup): void {};

    public onRender() {
        // Selected item
        let selected: SidebarItem = null;

        // Get selected tab
        for (let group of this.options.groups) {
            for (let item of group.items) {
                if (item.selected) {
                    selected = item;
                }
            }
        }

        // Get all tab contents
        let articles = this.parentComponent.querySelectorAll<HTMLElement>(`article[class*="sidebar-content"]`);

        // Hide them and show only selected one
        for (let article of articles) {
            article.style.display = (article.classList.contains(`sidebar-content-${StringHelper.toKebabCase(selected.name)}`)) ? "block" : "none";
        }
    }

    public find(itemName: string): SidebarItem {
        // In all groups and all group items
        for (let group of this.options.groups) {
            for (let item of group.items) {
                // Match?
                if (itemName == item.name) {
                    return item;
                }
            }
        }

        // Not found?
        return null;
    }

    public select(itemName: string): void {
        let item = this.find(itemName);

        // Group not found?
        if (!item) {
            Log.w(`Unknown item ${itemName} to select in ${this.id}`);
            return;
        }

        // Already selected?
        if (item.selected) {
            return;
        }

        // Deselect all groups and items
        for (let group of this.options.groups) {
            for (let item of group.items) {
                item.selected = false;
            }
        }

        // Select if not readonly
        if (!item.readonly) {
            // Select current
            item.selected = true;

            // Redraw
            this.render();
        }

        // OnItemSelect handler
        this.onSelect(item);
    }

    public toggle(): void {
        // Change type
        this.options.type = (this.options.type == "Expanded") ? "Collapsed" : "Expanded";

        // Set attribute to apply expanded or collapsed style
        this.setAttribute("type", this.options.type);
    }
}
