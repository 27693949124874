// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/v2/24/24-dashboard-02.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/assets/images/icons/v2/24/24-charging-ccs-sae.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/assets/images/icons/v2/24/24-archive.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/assets/images/icons/v2/24/24-calendar.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("/assets/images/icons/v2/24/24-halflist.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("/assets/images/icons/v2/24/24-ticket.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("/assets/images/icons/v2/24/24-user.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("/assets/images/icons/v2/24/24-export.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("/assets/images/icons/v2/24/24-info.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("/assets/images/icons/v2/24/24-arrow-left.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("/assets/images/icons/v2/24/24-arrow-right.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-sidebar {
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: width;
  display: block;
  user-select: none;
}
pinko-sidebar div.group {
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;
  padding: 0 12px;
}
pinko-sidebar div.group div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-sidebar div.item {
  display: flex;
  flex-direction: row;
  cursor: pointer !important;
  user-select: none;
  height: 40px;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  border-radius: 8px;
}
pinko-sidebar div.item:hover {
  background-color: rgba(22, 22, 22, 0.0392156863);
}
pinko-sidebar div.item div.icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  background-color: rgba(22, 22, 22, 0.5019607843);
}
pinko-sidebar div.item div.icon-cockpit {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
pinko-sidebar div.item div.icon-partners {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
pinko-sidebar div.item div.icon-contracts {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
pinko-sidebar div.item div.icon-calculations {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
pinko-sidebar div.item div.icon-offers {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
pinko-sidebar div.item div.icon-tasks {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}
pinko-sidebar div.item div.icon-users {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}
pinko-sidebar div.item div.icon-logout {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
}
pinko-sidebar div.item div.icon-details {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_8___});
}
pinko-sidebar div.item div.label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
  overflow: hidden;
  white-space: nowrap;
}
pinko-sidebar div.item-selected {
  background-color: rgba(128, 0, 247, 0.3019607843) !important;
}
pinko-sidebar div.item-separated {
  margin-bottom: 16px;
}
pinko-sidebar[type=Expanded] {
  width: 256px;
}
pinko-sidebar[type=Expanded] div.item div.icon-toggle {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_9___});
}
pinko-sidebar[type=Collapsed] {
  width: 48px;
}
pinko-sidebar[type=Collapsed] div.item div.icon-toggle {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_10___});
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-sidebar/pinko-sidebar.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAKA;EC8EE,uDAAA;EACA,0BAAA;ED7EA,cAAA;EACA,iBAAA;AAHF;AAKE;ECyBA,aAAA;EACA,mBAAA;EDxBE,YAAA;EACA,mBAAA;EACA,eAAA;AAFJ;AAII;ECPF,4CAAA;ECsKA,eAAA;EACA,kBAAA;EACA,mBAAA;EF/JI,qCGEgB;AHDtB;AAGE;ECaA,aAAA;EACA,mBAAA;EAsBA,0BAAA;EACA,iBAAA;EDlCE,YAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;EACA,kBAAA;AACJ;AACI;EACE,gDGxBQ;AHyBd;AAEI;ECPF,WDQsC;ECPtC,YDO4C;ECN5C,eDMsC;ECLtC,gBDK4C;EACxC,gDGLgB;AHQtB;AADM;EACE,mDAAA;AAGR;AAAM;EACE,mDAAA;AAER;AACM;EACE,mDAAA;AACR;AAEM;EACE,mDAAA;AAAR;AAGM;EACE,mDAAA;AADR;AAIM;EACE,mDAAA;AAFR;AAKM;EACE,mDAAA;AAHR;AAMM;EACE,mDAAA;AAJR;AAOM;EACE,mDAAA;AALR;AASI;EC3EF,0CAAA;ECkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EFyDI,cG5De;EH6Df,gBAAA;EACA,mBAAA;AAJN;AAOI;EACE,4DAAA;AALN;AAQI;EACE,mBAAA;AANN;AAUE;EACE,YAAA;AARJ;AAWM;EACE,mDAAA;AATR;AAcE;EACE,WAAA;AAZJ;AAeM;EACE,oDAAA;AAbR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
