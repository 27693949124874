// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/v2/16/16-dart-down.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-select {
  display: block;
  position: static;
}
pinko-select label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
  margin-bottom: 4px;
}
pinko-select div.input {
  position: relative;
}
pinko-select div.input input {
  cursor: pointer !important;
  user-select: none;
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: border-color;
  display: block;
  width: 100%;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  color: #1F1F1F;
  background-color: #FFFFFF;
  padding: 8px 40px 8px 12px;
  border-radius: 4px;
  border: 1px solid rgba(111, 111, 111, 0.2);
}
pinko-select div.input input:focus {
  border-color: #8000F7 !important;
}
pinko-select div.input input::placeholder {
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-select div.input div.icon {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 16px;
  height: 16px;
  background-color: rgba(22, 22, 22, 0.5019607843);
  mask-size: 16px 16px;
  pointer-events: none;
}
pinko-select div.input div.icon-down {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
pinko-select div.description {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-select div.reason {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  position: absolute;
  color: #FF1267;
  margin-top: 4px;
}
pinko-select[type=Input] {
  flex-grow: 1;
  margin-bottom: 24px;
  /*&:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 12px;
    right: 16px;
    background-color: colors.\$color-icon-tertiary;
    mask-size: 16px 16px;
    mask-image: url("/assets/images/icons/tools/16/16-tools-dropdown_arrow.svg");
    pointer-events: none;
  }*/
}
pinko-select[borderless=true] div.input input {
  border-color: #FFFFFF;
}
pinko-select[invalid=true] div.input input {
  border-color: #FF1267 !important;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-select/pinko-select.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAIA;EACE,cAAA;EACA,gBAAA;AAHF;AAKE;ECPA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EFGE,cGQiB;EHPjB,kBAAA;AAAJ;AAGE;EACE,kBAAA;AADJ;AAGI;ECyCF,0BAAA;EACA,iBAAA;EA1DA,0CAAA;ECkBA,eAAA;EACA,kBAAA;EACA,mBAAA;ED8DA,uDAAA;EACA,iCAAA;ED/DI,cAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,cGRe;EHSf,yBGqCQ;EHpCR,0BAAA;EACA,kBAAA;EACA,0CAAA;AAIN;AAFM;EACE,gCAAA;AAIR;AADM;EACE,qCGjBc;AHoBtB;AACI;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,gDGfgB;EHgBhB,oBAAA;EACA,oBAAA;AACN;AACM;EACE,mDAAA;AACR;AAIE;ECxDA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EFoDE,qCGvCkB;AHwCtB;AAEE;EC7DA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EFyDE,kBAAA;EACA,cGhEe;EHiEf,eAAA;AAGJ;AAAE;ECpBA,YAAA;EDsBE,mBAAA;EAEA;;;;;;;;;;;IAAA;AAYJ;AAIM;EACE,qBG1BM;AHwBd;AASM;EACE,gCAAA;AAPR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
