// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `partner-contact-dialog {
  width: 528px;
  height: 800ox;
}`, "",{"version":3,"sources":["webpack://./src/incinet/components/partner-contact-dialog/partner-contact-dialog.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,aAAA;AAFF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
