// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-overlay {
  display: flex;
  flex-direction: column;
  animation: keyframes-fade-in 300ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(22, 22, 22, 0.1);
  align-items: center;
  justify-content: center;
  z-index: 99;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-overlay/pinko-overlay.scss","webpack://./src/pinko/styles/_mixins.scss"],"names":[],"mappings":"AAGA;ECqCE,aAAA;EACA,sBAAA;EA+CA,oEAAA;EDlFA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,uCAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AADF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
