export class MathHelper {

    public static lerp(value1: number, value2: number, amount: number): number {
        return value1 * (1 - amount) + value2 * amount;
    }

    public static invlerp(value1: number, value2: number, amount: number): number {
        return this.clamp((amount - value1) / (value2 - value1));
    }

    public static clamp(value: number, min: number = 0, max: number = 1): number {
        return Math.min(max, Math.max(min, value));
    }

    public static range(x1: number, y1: number, x2: number, y2: number, amount: number) {
        return this.lerp(x1, y1, this.invlerp(x2, y2, amount));
    }

    public static random(x: number, y: number): number {
        return Math.floor(Math.random() * (y - x + 1)) + x;
    }

}