// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-preview, .pinko-preview {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  width: 520px;
  position: relative;
  box-sizing: border-box;
  background-color: #FFFFFF;
  overflow: hidden;
}
pinko-preview > header, .pinko-preview > header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 24px 16px 24px;
}
pinko-preview > header div.title div.label, .pinko-preview > header div.title div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
}
pinko-preview > header div.title div.description, .pinko-preview > header div.title div.description {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
  margin-top: 4px;
}
pinko-preview > header div.toolbar, .pinko-preview > header div.toolbar {
  display: flex;
  flex-direction: row;
}
pinko-preview > nav, .pinko-preview > nav {
  padding: 0 24px 16px 24px;
}
pinko-preview > article, .pinko-preview > article {
  flex-grow: 1;
  overflow: auto;
  padding: 0 24px;
}
pinko-preview > article div.activities, .pinko-preview > article div.activities {
  margin-bottom: 32px;
}
pinko-preview > article div.activities div.period, .pinko-preview > article div.activities div.period {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: rgba(31, 31, 31, 0.6980392157);
  padding: 12px 0 8px 0;
  text-align: center;
}
pinko-preview > article div.activities div.activity, .pinko-preview > article div.activities div.activity {
  max-width: 64%;
  margin-bottom: 4px;
}
pinko-preview > article div.activities div.activity div.user, .pinko-preview > article div.activities div.activity div.user {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: rgba(31, 31, 31, 0.6980392157);
  padding: 8px 0 4px 0;
}
pinko-preview > article div.activities div.activity p.text, .pinko-preview > article div.activities div.activity p.text {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  padding: 8px 12px;
  border-radius: 8px;
}
pinko-preview > article div.activities div.activity p.text span.timestamp, .pinko-preview > article div.activities div.activity p.text span.timestamp {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  display: block;
  color: rgba(31, 31, 31, 0.6980392157);
  text-align: right;
  margin-top: 4px;
}
pinko-preview > article div.activities div.activity-user, .pinko-preview > article div.activities div.activity-user {
  margin-left: auto;
}
pinko-preview > article div.activities div.activity-user div.user, .pinko-preview > article div.activities div.activity-user div.user {
  text-align: right;
}
pinko-preview > article div.activities div.activity-user p.text, .pinko-preview > article div.activities div.activity-user p.text {
  color: #1F1F1F;
  background-color: rgba(22, 22, 22, 0.0392156863);
}
pinko-preview > article div.activities div.activity-system, .pinko-preview > article div.activities div.activity-system {
  margin-right: auto;
}
pinko-preview > article div.activities div.activity-system div.user, .pinko-preview > article div.activities div.activity-system div.user {
  text-align: left;
}
pinko-preview > article div.activities div.activity-system p.text, .pinko-preview > article div.activities div.activity-system p.text {
  color: #1F1F1F;
  background-color: rgba(128, 0, 247, 0.3019607843);
}
pinko-preview > article div.submit, .pinko-preview > article div.submit {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
pinko-preview > footer, .pinko-preview > footer {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 24px;
}
@media only screen and (max-width: 1280px) {
  pinko-preview, .pinko-preview {
    width: 352px;
  }
}
@media only screen and (max-width: 1112px) {
  pinko-preview, .pinko-preview {
    width: 352px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-preview/pinko-preview.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss","webpack://./src/pinko/styles/_styles.scss"],"names":[],"mappings":"AAIA;EC6CE,YAAA;EATA,aAAA;EACA,sBAAA;EAuDA,mBAAA;EDxFA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,yBEqDY;EFpDZ,gBAAA;AAFF;AAIE;ECqBA,aAAA;EACA,mBAAA;EDpBE,8BAAA;EACA,uBAAA;EACA,4BAAA;AADJ;AAIM;ECZJ,4CAAA;EE2LA,eAAA;EACA,kBAAA;EACA,mBAAA;EH/KM,cELa;AFMrB;AAEM;ECzBJ,0CAAA;EEkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EHOM,qCERc;EFSd,eAAA;AAGR;AACI;ECEF,aAAA;EACA,mBAAA;ADAF;AAEE;EACE,yBAAA;AAAJ;AAGE;ECOA,YAAA;EDLE,cAAA;EACA,eAAA;AADJ;AAGI;EACE,mBAAA;AADN;AAGM;ECjDJ,0CAAA;EEIA,eAAA;EACA,kBAAA;EACA,mBAAA;EH6CM,qCEjCe;EFkCf,qBAAA;EACA,kBAAA;AAER;AACM;EACE,cAAA;EACA,kBAAA;AACR;AACQ;EC5DN,0CAAA;EEIA,eAAA;EACA,kBAAA;EACA,mBAAA;EHwDQ,qCE5Ca;EF6Cb,oBAAA;AAIV;AADQ;EClEN,0CAAA;EEkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EHgDQ,iBAAA;EACA,kBAAA;AAMV;AAJU;ECvER,0CAAA;EEIA,eAAA;EACA,kBAAA;EACA,mBAAA;EHmEU,cAAA;EACA,qCExDW;EFyDX,iBAAA;EACA,eAAA;AASZ;AALQ;EACE,iBAAA;AAOV;AALU;EACE,iBAAA;AAOZ;AAJU;EACE,cEvES;EFwET,gDElFE;AFwFd;AAFQ;EACE,kBAAA;AAIV;AAFU;EACE,gBAAA;AAIZ;AADU;EACE,cEpFS;EFqFT,iDEzFE;AF4Fd;AAGI;EC1EF,aAAA;EACA,mBAAA;ED2EI,QAAA;AAAN;AAIE;EChFA,aAAA;EACA,mBAAA;EDiFE,QAAA;EACA,aAAA;AADJ;AAIE;EArHF;IAsHI,YAAA;EADF;AACF;AAGE;EAzHF;IA0HI,YAAA;EAAF;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
