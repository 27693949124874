import "./offer-module-dialog.scss";
import template from "./offer-module-dialog.hbs";
import { component } from "../../../../hiyo/decorators.js";
import { FormDialog } from "../../common/form-dialog/form-dialog.js";

@component(template)
export class OfferModuleDialog extends FormDialog {

    public onAttach(): void {
        // Sort module name
        this.context.data.pricelist.modules.sort((a, b) => a.name.localeCompare(b.name));
    }

    public select(i: number): void {
        // Get module from pricelist
        let module = this.context.data.pricelist.modules[i];

        // Submit with module
        this.submit(module);
    }
}