// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-filter {
  margin-bottom: 24px;
}
pinko-filter div.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}
pinko-filter div.header div.title div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
}
pinko-filter div.header div.title div.path {
  margin-top: 4px;
}
pinko-filter div.header div.title div.path span {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-filter div.header div.title div.path span:last-child {
  color: #1F1F1F;
}
pinko-filter div.header div.toolbar {
  display: flex;
  flex-direction: row;
}
pinko-filter div.selects {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-filter/pinko-filter.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAIA;EACE,mBAAA;AAHF;AAKE;EC4BA,aAAA;EACA,mBAAA;ED3BE,8BAAA;EACA,uBAAA;EACA,mBAAA;AAFJ;AAKM;ECLJ,4CAAA;EC2LA,eAAA;EACA,kBAAA;EACA,mBAAA;AFvLF;AAGM;EACE,eAAA;AADR;AAGQ;ECpBN,0CAAA;ECkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EFEQ,qCGHY;AHKtB;AAAU;EACE,cGRS;AHUrB;AAII;ECGF,aAAA;EACA,mBAAA;ADJF;AAQE;ECLA,aAAA;EACA,mBAAA;EDME,mBAAA;EACA,QAAA;AALJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
