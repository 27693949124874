import { query } from "../../../../hiyo/decorators.js";
import { FormDialogOptions } from "./types.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { PinkoDialog } from "../../../../pinko/components/pinko-dialog/pinko-dialog.js";
import { PinkoForm } from "../../../../pinko/components/pinko-form/pinko-form";
import { Log } from "../../../../hiyo/log.js";

export abstract class FormDialog<T extends FormDialogOptions = FormDialogOptions> extends PinkoDialog<IncinetContext, T> {

    // Child components
    @query("pinko-form") public form: PinkoForm;

    public render(): void {
        // This is an important routine to store and restore data when form is rendered.
        // If data comes from options, it will be used on default entry.
        let data = this.form?.getData() || this.options.data;

        // When render is called, all form data fields will be redrawn blank
        super.render();

        // Restore form data
        this.form?.setData(data);
    }

    public async trySubmit(): Promise<any> {
        Log.w(`${this.constructor.name}.trySubmit() not implemented. Forgot to override it?`);
    }

    public async submitWithData(): Promise<void> {
        // Validation
        if (!this.form.validate()) {
            return;
        }

        // Return form data as result
        super.submit(this.form.getData());
    }

    public async submitWithTry(): Promise<void> {
        // Validation
        if (!this.form.validate()) {
            return;
        }

        // Show loader
        this.lock();

        try {
            // Call try implementation
            await this.trySubmit();
        }
        catch (e) {
            if (e.status == 422) {
                //this.form.setValidationErrors(e.response);
                console.error(e.response);
                return;
            }
        }

        // Hide loader
        this.unlock();

        // Super call
        super.submit();
    }
}