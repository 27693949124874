import "./pinko-confirm.scss";
import template from "./pinko-confirm.hbs";
import { component } from "../../../hiyo/decorators.js";
import { Context } from "../../../hiyo/context.js";
import { PinkoDialog } from "../pinko-dialog/pinko-dialog.js";
import { PinkoConfirmOptions } from "./types.js";

@component(template)
export class PinkoConfirm extends PinkoDialog<Context, PinkoConfirmOptions> {

    // Event handling methods
    public onCancel(): void {}; // Dialog was canceled
    public onConfirm(): void {}; // Dialog was confirmed

    public cancel(): void {
        // Close self
        this.remove();

        // OnConfirm handler
        this.onCancel();
    };

    public confirm(): void {
        // Close self
        this.remove();

        // OnConfirm handler
        this.onConfirm();
    };
}