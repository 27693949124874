import { component } from "../../../hiyo/decorators.js";
import { PinkoTable } from "../../../pinko/components/pinko-table/pinko-table.js";
import { IncinetContext } from "../../context/incinet-context.js";
import { TableRow } from "../../../pinko/components/pinko-table/types.js";

@component(null, true)
export class UserBrowser extends PinkoTable<IncinetContext> {

    constructor(context: IncinetContext) {
        super(context, {
            type: "SingleSelect",
            size: "Short",
            height: "100%",
            rows: {
                id: "id",
                decorator: (data: any): string => {
                    return data.disabled ? "disabled" : null;
                }
            },
            columns: [
                {
                    name: "name",
                    type: "String",
                    property: "name",
                    label: "tables.columns.name",
                    width: 280,
                    sortable: true,
                    selected: true
                },
                {
                    name: "lastLogin",
                    type: "DateTime",
                    property: "lastLogin",
                    label: "tables.columns.login",
                    width: 160,
                    sortable: true
                },
                {
                    name: "email",
                    type: "String",
                    property: "email",
                    label: "tables.columns.email",
                    ellipsis: true,
                    sortable: true,
                    minWidth: 280
                }
            ]
        });
    }

    public onRowSelect(row: TableRow): void {
        console.info(row);
    }

    public async load(): Promise<void> {
        // Load users
        let users = await this.context.api.getUsers();

        // Assign user
        this.options.data = users.data;
    }

}