export const LOCALE = localStorage.getItem("hiyo.langId") || navigator.language;

export class DateHelper {

    public static toDateString(t: string | Date | number): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { day: "numeric", month: "numeric", year: "numeric" });
    }

    public static toShortDateString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { day: "numeric", month: "numeric" });
    }

    public static toShortIsoDateString(t: string | Date): string {
        if (!t) {
            return null;
        }

        return new Date(t).toISOString().substring(0, 10);
    }

    public static toHour(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).getHours().toString();
    }

    public static toMinute(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).getMinutes().toString();
    }

    public static toYear(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { year: "numeric" });
    }

    public static toMonthString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { month: "long" });
    }

    public static toShortMonthString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { month: "short" });
    }

    public static toWeekDayString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { weekday: "long" });
    }

    public static toShortWeekDayString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleDateString(LOCALE, { weekday: "short" });
    }

    public static toTimeString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleTimeString(LOCALE, { hour: "2-digit", minute: "2-digit", second: "2-digit" });
    }

    public static toShortTimeString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return new Date(t).toLocaleTimeString(LOCALE, { hour: "2-digit", minute: "2-digit" });
    }

    public static toDateTimeString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return DateHelper.toDateString(t) + " " + DateHelper.toTimeString(t);
    }

    public static toShortDateTimeString(t: string | Date): string {
        if (!t) {
            return null;
        }
        return DateHelper.toShortDateString(t) + " " + DateHelper.toShortTimeString(t);
    }

    public static toLocalIsoDate(t: string | Date): string {
        if (!t) {
            return null;
        }

        let date = new Date(t);

        // Shift to local time
        date = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));

        return date.toISOString().substring(0, 16);
    }

    public static toLocalIsoTime(t: string | Date): string {
        if (!t) {
            return null;
        }

        let date = new Date(t);

        // Shift to local time
        date = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));

        return date.toISOString().substring(11, 16);
    }

    public static toDuration(t: number): string {
        if (t == null) {
            return null;
        }

        // Duration string
        let duration = "";

        // Miliseconds
        if (t < 1) {
            duration += "<1s";
        }

        // Seconds
        if (Math.floor(t % 60) > 0) {
            duration += Math.floor(t % 60) + "s "
        }

        // Minutes
        if (t >= 60 && Math.floor((t / 60) % 60) > 0) {
            duration = Math.floor((t / 60) % 60) + "m " + duration;
        }

        // Hours
        if (t >= 3600 && Math.floor((t / 3600) % 24) > 0) {
            duration = Math.floor((t / 3600) % 24) + "h " + duration;
        }

        // Days
        if (t >= 86400) {
            duration = Math.floor(t / 86400) + "d " + duration;
        }

        return duration.trim();
    }

}
