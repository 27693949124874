export class SystemHelper {

    public static id = 0;

    public static async sleep(milliseconds: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    public static newUid(prefix: object | string): string {
        return  `${prefix?.constructor.name || prefix}#${++SystemHelper.id}`;
    }

    public static newUuid(): string {
        let dt = Date.now();

        // Custom replacer
        let replacer = (c: string) => {
            let r = (dt + Math.random() * 16) %16 | 0;
            dt = Math.floor(dt / 16);
            return (c == "x" ? r : (r&0x3 | 0x8)).toString(16);
        }

        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, replacer);
    }

}