import "./pinko-dialog.scss";
import template from "./pinko-dialog.hbs";
import { component } from "../../../hiyo/decorators.js";
import { PinkoDialogOptions } from "./types.js";
import { Context } from "../../../hiyo/context.js";
import { PinkoOverlay } from "../pinko-overlay/pinko-overlay.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";

@component(template)
export class PinkoDialog<U extends Context, T extends PinkoDialogOptions = PinkoDialogOptions> extends PinkoComponent<U, T> {

    // Event handling methods
    public onSubmit(result?: any): void {}; // Dialog was submitted with result

    public showModal(): void {
        // Add self to overlay and then to main page
        this.appendTo(new PinkoOverlay().appendTo(document.body));
    }

    public remove(): void {
        // Close via overlay if displayed
        if (this.parentComponent instanceof PinkoOverlay) {
            // Close self via overlay
            this.parentComponent.remove();
        }
        // Else remove self directly from DOM
        else {
            super.remove();
        }
    }

    public submit(result?: any): void {
        // Remove self
        this.remove();

        // OnClose handler
        this.onSubmit(result);
    }

}