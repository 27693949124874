// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `incinet-app {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  background-color: rgba(22, 22, 22, 0.0392156863);
  overflow: hidden;
  gap: 12px;
  padding: 12px;
}
incinet-app > nav {
  display: flex;
  flex-direction: column;
}
incinet-app > main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
incinet-app > aside {
  display: flex;
  flex-direction: column;
}
incinet-app > aside:empty {
  display: none;
}
incinet-app[logged=false] {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/incinet/components/incinet-app/incinet-app.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAGA;EC8CE,YAAA;EAdA,aAAA;EACA,mBAAA;ED9BA,gDEEY;EFDZ,gBAAA;EACA,SAAA;EACA,aAAA;AADF;AAGE;EC6BA,aAAA;EACA,sBAAA;AD7BF;AAGE;ECkCA,YAAA;EATA,aAAA;EACA,sBAAA;ADxBF;AAGE;ECoBA,aAAA;EACA,sBAAA;ADpBF;AAEI;EACE,aAAA;AAAN;AAIE;EACE,UAAA;AAFJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
