import "./pinko-overlay.scss";
import template from "./pinko-overlay.hbs";
import { component, events } from "../../../hiyo/decorators.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";

@component(template)
@events("mousedown", "keyup")
export class PinkoOverlay extends PinkoComponent {

    // Properties
    public zIndex: Number;
    public static zCurrent = 100;

    public onAttach() {
        // Add next level
        PinkoOverlay.zCurrent += 100;

        // Assign z-index
        this.zIndex = PinkoOverlay.zCurrent;

        // Assign to style
        this.style.zIndex = String(this.zIndex);
    }

    public onDetach() {
        // Remove level
        PinkoOverlay.zCurrent -= 100;
    }

    public onEvent(event: Event) {
        // Close self on click
        if (event.type == "mousedown" && event.target == this) {
            this.remove();
        }

        // Close self on ESC
        if (event.type == "keyup" && this.zIndex == PinkoOverlay.zCurrent && (<KeyboardEvent>event).code == "Escape") {
            this.remove();
        }
    }
}