import { StringHelper } from "./string-helper.js";

export function component(template?: any, shouldLoad?: boolean): ClassDecorator {
    return (target: any) => {
        // Component name according to custom element rules
        let name = StringHelper.toKebabCase(target.name);

        if (window.customElements.get(name)) {
            throw new Error(`Already an element is registered with the name ${name}`);
        }

        // Assign template
        if (template) {
            target.prototype.template = template;
        }

        // Set loading flag
        if (shouldLoad) {
            target.prototype.shouldLoad = shouldLoad;
        }

        // Register custom web component
        window.customElements.define(name, target);
    };
}

export function events(...events: string[]): ClassDecorator {
    return (target: any) => {
        target.prototype.events = events;
    };
}

export function query(selector: string) {
    return function(target: any, key: string) {
        // Array must be initialized here because this decorator calls before class constructor
        target.accessors = target.accessors || [];

        // Push accessor
        target.accessors.push({
            property: key,
            selector: selector
        });
    }
}