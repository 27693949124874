// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/assets/images/icons/v2/16/16-dart-down.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-select, partner-select {
  display: block;
  position: static;
}
pinko-select label, partner-select label {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
  margin-bottom: 4px;
}
pinko-select div.input, partner-select div.input {
  position: relative;
}
pinko-select div.input input, partner-select div.input input {
  cursor: pointer !important;
  user-select: none;
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: border-color;
  display: block;
  width: 100%;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  color: #1F1F1F;
  background-color: #FFFFFF;
  padding: 8px 40px 8px 12px;
  border-radius: 4px;
  border: 1px solid rgba(111, 111, 111, 0.2);
}
pinko-select div.input input:focus, partner-select div.input input:focus {
  border-color: #8000F7 !important;
}
pinko-select div.input input::placeholder, partner-select div.input input::placeholder {
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-select div.input div.icon, partner-select div.input div.icon {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 16px;
  height: 16px;
  background-color: rgba(22, 22, 22, 0.5019607843);
  mask-size: 16px 16px;
  pointer-events: none;
}
pinko-select div.input div.icon-down, partner-select div.input div.icon-down {
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
pinko-select div.description, partner-select div.description {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  color: rgba(22, 22, 22, 0.5019607843);
}
pinko-select div.reason, partner-select div.reason {
  font-family: "Incinet Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  position: absolute;
  color: #FF1267;
  margin-top: 4px;
}
pinko-select[type=Input], partner-select[type=Input] {
  flex-grow: 1;
  margin-bottom: 24px;
  /*&:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 12px;
    right: 16px;
    background-color: colors.\$color-icon-tertiary;
    mask-size: 16px 16px;
    mask-image: url("/assets/images/icons/tools/16/16-tools-dropdown_arrow.svg");
    pointer-events: none;
  }*/
}
pinko-select[borderless=true] div.input input, partner-select[borderless=true] div.input input {
  border-color: #FFFFFF;
}
pinko-select[invalid=true] div.input input, partner-select[invalid=true] div.input input {
  border-color: #FF1267 !important;
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-select/pinko-select.scss","webpack://./src/incinet/components/partner/partner-select/partner-select.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAIA;EACE,cAAA;EACA,gBAAA;ACHF;ADKE;EEPA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EHGE,cIQiB;EJPjB,kBAAA;ACAJ;ADGE;EACE,kBAAA;ACDJ;ADGI;EEyCF,0BAAA;EACA,iBAAA;EA1DA,0CAAA;ECkBA,eAAA;EACA,kBAAA;EACA,mBAAA;ED8DA,uDAAA;EACA,iCAAA;EF/DI,cAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,cIRe;EJSf,yBIqCQ;EJpCR,0BAAA;EACA,kBAAA;EACA,0CAAA;ACIN;ADFM;EACE,gCAAA;ACIR;ADDM;EACE,qCIjBc;AHoBtB;ADCI;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,gDIfgB;EJgBhB,oBAAA;EACA,oBAAA;ACCN;ADCM;EACE,mDAAA;ACCR;ADIE;EExDA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EHoDE,qCIvCkB;AHwCtB;ADEE;EE7DA,0CAAA;ECIA,eAAA;EACA,kBAAA;EACA,mBAAA;EHyDE,kBAAA;EACA,cIhEe;EJiEf,eAAA;ACGJ;ADAE;EEpBA,YAAA;EFsBE,mBAAA;EAEA;;;;;;;;;;;IAAA;ACYJ;ADIM;EACE,qBI1BM;AHwBd;ADSM;EACE,gCAAA;ACPR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
