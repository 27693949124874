// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pinko-section {
  display: block;
  margin-bottom: 32px;
}
pinko-section > div.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
pinko-section > div.title div.label {
  font-family: "Incinet Semi Bold", sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
}
pinko-section > section {
  background-color: rgba(22, 22, 22, 0.0392156863);
  padding: 20px 24px;
  margin-bottom: 8px;
  border-radius: 8px;
}
pinko-section > section div.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
pinko-section > section div.row > div.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
pinko-section > section div.row > div.label {
  font-family: "Incinet Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: rgba(31, 31, 31, 0.6980392157);
  padding: 8px 0;
}
pinko-section > section div.row > div.value {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
  padding: 8px 0;
}
pinko-section > section div.row > div.value-right {
  text-align: right;
}
pinko-section > section div.row > div.value-flex {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
pinko-section > section div.row > p {
  font-family: "Incinet Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: #1F1F1F;
}
pinko-section > section div.row > p:first-child {
  flex-grow: 1;
}
pinko-section > section div.row > div.actions {
  margin-right: -8px;
}
pinko-section > section div.row-top {
  align-items: flex-start;
}
pinko-section > section[onclick] {
  cursor: pointer !important;
  user-select: none;
  transition: all 100ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: background-color;
  background-color: rgba(22, 22, 22, 0.0392156863);
}`, "",{"version":3,"sources":["webpack://./src/pinko/components/pinko-section/pinko-section.scss","webpack://./src/pinko/styles/_mixins.scss","webpack://./src/pinko/styles/_styles.scss","webpack://./src/pinko/styles/_colors.scss"],"names":[],"mappings":"AAKA;EACE,cAAA;EACA,mBAAA;AAJF;AAME;EC0BA,aAAA;EACA,mBAAA;EDzBE,mBAAA;EACA,8BAAA;EACA,mBAAA;AAHJ;AAKI;ECNF,4CAAA;EC2LA,eAAA;EACA,kBAAA;EACA,mBAAA;EFrLI,cGCe;AHDrB;AAIE;EACE,gDGdU;EHeV,kBAAA;EACA,kBAAA;EACA,kBAAA;AAFJ;AAII;ECQF,aAAA;EACA,mBAAA;EDPI,mBAAA;EACA,8BAAA;EACA,SAAA;AADN;AAGM;ECOJ,aAAA;EACA,sBAAA;EDNM,mBAAA;AAAR;AAGM;ECjCJ,yCAAA;EC4FA,eAAA;EACA,kBAAA;EACA,mBAAA;EF3DM,qCGrBe;EHsBf,cAAA;AAER;AACM;EC3CJ,0CAAA;ECkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EFyBM,cG5Ba;EH6Bb,cAAA;AAIR;AAFQ;EACE,iBAAA;AAIV;AADQ;EClBN,aAAA;EACA,mBAAA;EDmBQ,QAAA;AAIV;AAAM;EC1DJ,0CAAA;ECkBA,eAAA;EACA,kBAAA;EACA,mBAAA;EFwCM,cG3Ca;AHgDrB;AAHQ;ECdN,YAAA;ADoBF;AADM;EACE,kBAAA;AAGR;AAAM;EACE,uBAAA;AAER;AAEI;ECnBF,0BAAA;EACA,iBAAA;EAwBA,uDAAA;EACA,qCAAA;EDJI,gDGxEQ;AH0Ed","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
