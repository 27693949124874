import { component } from "../../../hiyo/decorators.js";
import { PinkoFilter } from "../../../pinko/components/pinko-filter/pinko-filter.js";
import { PinkoSelect } from "../../../pinko/components/pinko-select/pinko-select.js";
import { SearchItem } from "../../../pinko/components/pinko-search/types.js";
import { PinkoTable } from "../../../pinko/components/pinko-table/pinko-table.js";
import { TableBrowser } from "../table-browser/table-browser.js";
import { PartnerPreview } from "../partner-preview/partner-preview.js";
import { PartnerDetail } from "../partner-detail/partner-detail.js";
import { TableAction, TableRow } from "../../../pinko/components/pinko-table/types.js";
import { FilterItem } from "../../../pinko/components/pinko-filter/types.js";
import { PartnerFormDialog } from "../partner-form-dialog/partner-form-dialog.js";

@component()
export class PartnerBrowser extends TableBrowser {

    public constructor() {
        super();

        // Browser options
        this.options = {
            url: `/partners`
        }
    }

    public createFilter() {
        // Create filter
        this.filter = new PinkoFilter(this.context, {
            title: "components.PartnerBrowser.title",
            path: ["Company", "Partners"],
            items: [
                {
                    name: "Add",
                    icon: "Add",
                },
                {
                    name: "Reload",
                    icon: "Reload",
                }
            ],
            selects: [
                new PinkoSelect(this.context, {
                    type: "Tag",
                    name: "addresses.country",
                    placeholder: "placeholders.country",
                    enums: "AddressCountry",
                    autosubmit: true
                })
            ],
            resolver: async (term: string): Promise<SearchItem[]> => {
                // Find partners
                let partners = await this.context.api.getResource(`partners?search=${term}`);

                // Return as items
                return partners.data?.map(x => ({
                    name: x.name,
                    label: x.name.replace(new RegExp(`(${term})`, "gi"), "<strong>$1</strong>"),
                    data: x
                }));
            }
        });

        // Item selected
        this.filter.onItemSelect = async (item: FilterItem) => {
            // Reload?
            if (item.name == "Reload") {
                await this.load();
            }

            // Add?
            if (item.name == "Add") {
                this.showForm();
            }
        }
    }

    public createTable() {
        // Create table
        this.table = new PinkoTable(this.context, {
            type: "SingleSelect",
            size: "Short",
            //height: "100%",
            rows: {
                id: "_id",
                decorator: (data: any): string => {
                    return data.disabled ? "disabled" : null;
                }
            },
            columns: [
                {
                    name: "name",
                    type: "String",
                    property: "name",
                    label: "columns.name",
                    width: 280,
                    sortable: true,
                    selected: true
                },
                {
                    name: "addresses.country",
                    type: "String",
                    property: (data: any) => {
                        return data.addresses?.find(x => x.type == "Legal")?.country
                    },
                    label: "columns.country",
                    width: 120,
                    ellipsis: true,
                    sortable: true
                },
                {
                    name: "note",
                    type: "String",
                    property: "note",
                    label: "columns.note",
                    minWidth: 280,
                    sortable: true
                }
            ],
            actions: [
                {
                    name: "Edit",
                    label: "labels.edit",
                },
                {
                    name: "Detail",
                    label: "labels.detail",
                }
            ]
        });

        // Action selected
        this.table.onActionSelect = async (row: TableRow, action: TableAction) => {
            // Edit?
            if (action.name == "Edit") {
                this.showForm(row.data);
            }

            // Detail?
            if (action.name == "Detail") {
                await this.showDetail(row.data);
            }
        }
    }

    public createPreview(): void {
        // Create preview
        this.preview = new PartnerPreview(this.context, {
            items: [
                {
                    name: "Detail",
                    label: "Detail"
                }
            ]
        });
    }

    public createDetail() {
        // Create detail
        this.detail = new PartnerDetail(this.context);
    }

    public showForm(data?: any): void {
        // Create dialog
        let dialog = new PartnerFormDialog(this.context, {
            data: data
        });

        // Reload table on submit
        dialog.onSubmit = async () => {
            await this.load();
        }

        // Show modal
        dialog.showModal();
    }

}